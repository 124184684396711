
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
.body {
  font-family: 'Poppins-Bold' !important;
}
.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color:#f6f6f6 !important;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.rcorners2 {
  border-radius: 25px;
  border: 2px solid #000000;
  padding: 20px;
  width: 150px;
  height: 70px;
}

.Logo {
  width: 140.5px;
  height: 45px;
  object-fit: contain;
}

.Rectangle {  
  border-radius: 31px;
  background-color: #f3670d;
}

.Signin {
  width: 53px;
  height: 23px;
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f3670d;
  margin:0;
}


.Join-with-your-Profe {
   font-family: 'Poppins-Regular';
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #34495e;
  margin:0;
}

.Gridimage1 {
  width: 100%;
  height: 100%;
  zoom:50%;
  object-fit: contain;
}

.for-any-job-online {
   font-family: 'Poppins-Regular';
  font-size: 52px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
  text-align: left;
  margin:0;
}

.for-any-job-online2 {
  font-family: 'Poppins-Regular';
 font-size: 19px;
 font-weight: 300;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #34495e;
 text-align: left;
 margin:0;
}
.Hire-the-best-Gigs {
   font-family: 'Poppins-Regular';
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
  text-align: left;
  margin:0;
}

.Thousand-of-people-u {
   font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
  text-align: left;
}

.Best-Online-Gigs-Net {
   font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: left;
}

.Join-for-Free {
   font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.Join-for-FreeRectangle {
  border-radius: 32.5px;
  background-color: #f3670d;
}

.Gridimage2 {
  width: 211px;
  height: 158px;
  object-fit: contain;
  margin: 0px 0px 0px 0px;
}

.Gridimage3 {
  width: 186px;
  height: 146px;
  object-fit: contain;
}

.PaperStyle1{
  background-color: #f1ece6 !important;
}

.Lakh-Online-Jobs {
   font-family: 'Poppins-Regular';
  font-size: 33px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b24020;
}

.Tired-of-going-aroun {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #6a6a6a;
}
.How-it-works {
   font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f3670d;
}

.Company-budget-Get-Copy {
   font-family: 'Poppins-Regular';
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.Find-the-right-job-o {
  font-family: 'Poppins Regular';
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #34495e;
}

.Gridimage4 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Check-out-the-Gigs-s {
   font-family: 'Poppins-Regular';
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #34495e;
}

.PaperStyle2 {
  background-color: #ecffff !important;
}

.Gridimage5 {
  width: 99px;
  height: 99px;
  object-fit: contain;
}

.Gridimage6 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Global-network-of-ov {
   font-family: 'Poppins-Regular';
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #34495e;
}

.GigJobs-help-us-to-f {

   font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6a6a6a;
}

.CEO-Vroom {
   font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.CEO-Vroom .text-style-1 {
  font-weight: bold;
}

.Join {
   font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: center !important;
}


.Rectangle-Copy {
  border-radius: 31px;
  border: solid 1px #f3670d;
}

.Sign-in {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f3670d;
}

.SuggestionRectangle {
  margin:10px;
  border-radius: 24px;
  background-color: #f1f1f1;
}

.SuggestionText {
  margin: 15px;
  opacity: 0.6;
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.SuggestionsTitle {
  margin-left: 20px;
  font-family: 'Poppins-Regular';
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.arrow {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.Show-All-Jobs {
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 30px;
  color: #f3670d;
}


.JoinfreeButtonRectangle {
  border-radius: 27px;
  border: solid 1px #b24020;
}

.SignupButtonRectangle {
  border-radius: 27px;
  border: solid 1px #000;
}

.Join-FreeButtonText {
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b24020;
}
.Sign-upButtonText {
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.Already-a-member {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.Sign-up-faster-with {
  font-family: 'Poppins-Regular';
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #34495e;
}
.Signupwiht {
  width: 85%;
  height: 85%;
}

.Join-a-Global-networ {
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
}

.By-clicking-Agree- {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
}

.By-clicking-Agree- .text-style-1 {
  color: #f3670d;
}

.MuiFormControlLabel-label {
  font-family: 'Poppins-Regular';
  font-size: 17px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #34495e !important;
}

.MuiOutlinedInput-root{
    margin: 0.4em!important; 
    font-family: 'Poppins-Regular';
    /* font-family: 'Poppins-Regular';
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important; 
    background-color: #f8f8f8 !important;*/
}

.Personal-Details {
  font-family: 'Poppins-Regular';
  font-size: 24px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
  text-align: left;
}
.Create-a-full-profil {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e;
  text-align: left;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color:rgb(87, 86, 86) !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #0eae35 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #ff6a00 !important;
  font-size: 30px !important;
  font-weight: bold !important;
}
.CircularProgressbar {
  width: 70% !important;
  margin-bottom: 4px;
}

.Upload-CV {
  font-family: 'Poppins-Regular';
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #34495e !important;
  text-align: left;
  margin-left: 20px;}

  .TitleStyle {
    font-family: 'Poppins-Bold';
    font-size: 18px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #34495e !important;
    text-align: left;
    margin-left: 20px;}
  
  .Only-PDF-Doc-PPT-a {
    opacity: 0.5  !important;
    font-family: 'Poppins-Regular';
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #34495e !important;
    text-align: left;
    margin-left: 20px;
    margin-top: -10px;
  }

  
  .otherdetailsradiogroup {
    opacity: 0.6;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #34495e;
  }

  .MuiChip-label{   
      font-family: 'Poppins-Regular';
      font-size: 12px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: normal !important;
      color: #34495e !important;
  }
  .react-daterange-picker__wrapper{
    flex-direction: row-reverse;
    border: none !important;
  }
  .react-daterange-picker__clear-button{
    display: none;
  }

  .MuiDataGrid-row.Mui-odd {
    background-color:#f6f6f6;
  }

  .placement-logo {
    width: 140.5px;
    height: 45px;
    object-fit: contain;
}